import React from 'react'
import { Avatar, Skeleton } from 'components/ui'
import { Container } from 'components/shared'
import { getThumbnail } from 'utils/strapi'

const LodgeHeader = ({ loading, lodge, children }) => (
  <div className="pt-8 pb-4 border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800">
    <Container className="px-6">
      <div className="flex items-center gap-4 mb-6">
        {loading ? (
          <>
            <Skeleton variant="circle" width={80} height={80} />
            <div>
              <Skeleton height={15} width={100} className="mb-2" />
              <Skeleton height={25} width={180} />
            </div>
          </>
        ) : (
          <>
            <Avatar
              className="border-2 border-indigo cursor-pointer"
              shape="circle"
              src={getThumbnail(lodge?.logo)}
              size={80}
            />
            <div>
              <span>Masonic Lodge No. {lodge?.id === 383 ? 'TBD' : lodge?.id}</span>
              <h5>{lodge?.name}</h5>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col md:flex-row justify-between lg:items-center gap-4">
        {!loading && children}
        {loading && (
          <>
            <div className="flex flex-row">
              <Skeleton width={80} height={35} style={{ borderRadius: 8 }} className="mr-4" />
              <Skeleton width={80} height={35} style={{ borderRadius: 8 }} />
            </div>
            <div className="flex flex-row">
              <Skeleton width={100} height={35} style={{ borderRadius: 8 }} className="mr-4" />
              <Skeleton width={100} height={35} style={{ borderRadius: 8 }} />
            </div>
          </>
        )}
      </div>
    </Container>
  </div>
)

export default LodgeHeader
